import React, { useEffect, useState } from 'react';
import { getOutageAlerts, dismissOutageAlert } from '../../services/notificationService';
import AlertBanner from './alertBanner';
import { GlobalAnalytics } from '../../analytics';
import '../../styles/css/outageAlert.css';

const analytics = new GlobalAnalytics();

function OutageAlerts(props) {
    const [alerts, setAlerts] = useState([]);

    useEffect(() => {
        getOutageAlerts().then((data) => {
            setAlerts(data);
            props.setPadding(data.length);
        });
    }, []);

    function fireDismissAnalytics(alert) {
        analytics.fireOnClick({
            tag: '36.3',
            modelName: ' ',
            section: 'Outage Alerts',
            category: 'Dismiss Outage Alert',
            contentTitle: alert.title,
            mediaTitle: alert.title,
            module: 'Outage Alerts',
            label: 'Dismiss Outage Alert',
        });
    }

    function dismissAlert(alert) {
        dismissOutageAlert(alert.id);
        const updatedAlerts = alerts.filter(item => alert.id !== item.id);
        setAlerts(updatedAlerts);
        fireDismissAnalytics(alert);
    }

    return (alerts.length ? (
        alerts.map((alert, index) => <AlertBanner key={index} alert={alert} dismissAlert={dismissAlert} />)
    ) : (
        <></>
    ));
}

export default OutageAlerts;
