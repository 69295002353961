import 'whatwg-fetch';
import httpOption from './httpOption';
import AppSettings from '../appsettings';

export default function getGlossary() {
    const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/glossary`;
    const options = httpOption.GET();

    return fetch(url, options)
    .then((response) => {
        if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.json());
        }
        return Promise.reject(response);
    });
}

// The endpoint has been refactored to make it more perfomant.
// It is now not required to provide the 'glossary' parameter.
// Additionally, the 'text' parameter can be a single string or a list of strings.
// We have maintained both patterns to ensrue backward compatibility. 
export function getGlossaryTooltips(text, glossary) {
    const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/glossary/tooltips`;
    const options = httpOption.POST();
    const requestBody = {
        text: text,
        glossary: glossary
      }
    options.body = JSON.stringify(requestBody)
    return fetch(url, options)
    .then((response) => {
        if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.json());
        }
        return Promise.reject(response);
    });
}