import React, { useEffect } from 'react';
import Modal from 'react-responsive-modal';
import { ClipLoader } from 'react-spinners';
import '../../styles/css/pdfModal.css';
import { PdfStatus } from './vinLookup';

export function PdfModal(props) {

    useEffect(() => {
        if (props.status === PdfStatus.READY && props.modalType === "share") {
            props.closeModal();
            props.openEmailModal()
        }
    }, [props.status])

    const getMessage = () => {
        switch(props.status) {
            case PdfStatus.GENERATING:
                return 'Generating PDF. This may take up to 30 seconds.'
            case PdfStatus.READY:
                return 'The PDF has been generated.'
            case PdfStatus.ERROR:
                return 'Vehicle PDF could not be found.'
        }
    }

    const handleViewPdf = () => {
        props.closeModal();
        window.open(props.url, '_blank');
    }

  function vehicleSpotlightModalContent() {
    return (
        <div className="pdf-modal-container">
            <div className="inner">
                {getMessage()}
                {
                    props.status === PdfStatus.GENERATING
                    && (
                        <div className="load-spinner">
                            <ClipLoader
                                color={'#EB0A1E'}
                                loading={true}
                                size={50}
                            />
                        </div>
                    )
                }
                {
                    props.status === PdfStatus.READY
                    && (
                        <div className="save-button-container">
                            <div className="rounded-button" onClick={handleViewPdf}>
                                View PDF
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
  }

  return (
        <Modal
            open={props.openModal}
            onClose={props.closeModal}
            classNames={ { modal: 'pdf-modal' } }
            center={true}>
            {vehicleSpotlightModalContent()}
        </Modal>
  );
}
