import * as _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import AppSettings from "../../appsettings.js";
import getAnalytics from "../../services/analyticsService.js";
import getNavigation from "../../services/navigationService.js";
import "../../styles/css/topNav/topNav.css";
import BookmarkModal from "../bookmarks/bookmarkModalComponent.js";
import OutageAlerts from "../outageAlerts/outageAlerts.js";
import Avatar from "./headerComponents/avatar.js";
import EngageLogo from "./headerComponents/engageLogo.js";
import MainMenu from "./menus/mainMenu.js";
import TopNavSearchBar from "./search/topNavSearchBar.js";
import { generalNavButtonClicked } from "./topNav.analytics.js";
import { isMobileView } from "../utils/getScreenSize.js";
import getMisc from "../../services/miscService.js";
import { checkIfTournamentIsActive } from "../engageXP/utils.js";
import { postMessageToApp } from "../utils/hybridAppHelpers.js";

const TopNav = (props) => {
  const [bookmarksVisible, setBookmarksVisible] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedTopMenu, setSelectedTopMenu] = useState(null);
  const [displaySearchBar, setDisplaySearchBar] = useState(false);
  const [topNavMenuData, setTopNavMenuData] = useState(null);
  const [analytics, setAnalytics] = useState(null);
  const [tournamentsData, setTournamentsData] = useState(() => {
    const tournamentsData = sessionStorage.getItem("tournamentsData");
    return tournamentsData ? JSON.parse(tournamentsData) : null;
  });
  const [shouldShowTournamentsButton, setShouldShowTournamentsButton] = useState(false);
  const history = useHistory();

  const profileUrl = "/myAccount";

  const bookmarkCallback = () => setBookmarksVisible(true);

  const saveTournamentsData = (data) => {
    if (Object.keys(data).length && Object.keys(data.items).length) {
      setTournamentsData(data.items);
      sessionStorage.setItem("tournamentsData", JSON.stringify(data.items));
      return;
    }
    sessionStorage.setItem("tournamentsData", null);
  };

  const getTournamentsData = async () => {
    const data = await getMisc("xpTournaments", "en");
    saveTournamentsData(data);
  };

  useEffect(() => {
    if(!tournamentsData) {
      setShouldShowTournamentsButton(false);
      return;
    };
    const isTournamentActive = checkIfTournamentIsActive(tournamentsData);
    setShouldShowTournamentsButton(isTournamentActive);
    postMessageToApp("Show Tournaments Button", isTournamentActive);
  }, [tournamentsData]);

  const getMainMenuNavigation = () =>
    new Promise((resolve, reject) => {
      getNavigation("mainMenu")
        .then((mainMenuNavigation) => {
          const dropDownItems = _.orderBy(
            mainMenuNavigation.dropDownItems,
            "order"
          );
          dropDownItems.forEach((dropDownItem) => {
            dropDownItem.menuItems = _.orderBy(dropDownItem.menuItems, [
              "order",
              "title",
            ]);
            if (dropDownItem.isVehicleDropdown) {
              dropDownItem.menuItems.forEach((menuItem) => {
                menuItem.menuItems = _.orderBy(menuItem.menuItems, [
                  "order",
                  "title",
                ]);
              });
            }
          });
          setTopNavMenuData(dropDownItems);
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getNavigationAnalytics = async () => {
    const navigationAnalyticsRawData = await getAnalytics("navigation");
    setAnalytics(navigationAnalyticsRawData);
  };

  useEffect(() => {
    getMainMenuNavigation();
    getNavigationAnalytics();
    getTournamentsData();
  }, []);

  ////////////// SEARCH HELPER FUNCTIONS //////////////
  const toggleSearchBar = () => {
    setDisplaySearchBar(!displaySearchBar);
    setMenuOpen(false);
  };

  ////////////// CLICK HANDLER FUNCTIONS //////////////
  const clearMenuSelections = () => {
    props.updateHideContent(false);
    setMenuOpen(false);
    setDisplaySearchBar(false);
    setSelectedTopMenu(null);
  };

  const handleMenuClick = () => {
    if (menuOpen) {
      generalNavButtonClicked(analytics.clickAnalytics.hamburgerClose);
      clearMenuSelections();
    } else {
      generalNavButtonClicked(analytics.clickAnalytics.hamburgerOpen);
      setMenuOpen(true);
      setDisplaySearchBar(false);
    }
  };

  const handleGrayOverlayClick = () => {
    setSelectedTopMenu(null);
    setMenuOpen(false);
    setDisplaySearchBar(false);
  };

  const handleEngageXpButtonClick = (shouldRedirectToTournaments) => {
    setMenuOpen(false);
    setDisplaySearchBar(false);

    if (shouldRedirectToTournaments) {
      history.push("/engage-xp/tournaments");
      return;
    }
    generalNavButtonClicked(analytics.clickAnalytics.engageXpButton);
    history.push("/engage-xp/missions");
  };
  /////////////////////////////////////////////////////

  const GrayOverlay = () =>
    (selectedTopMenu || menuOpen || displaySearchBar) && (
      <div className="gray-overlay" onClick={handleGrayOverlayClick} />
    );

  const TopLeftIcon = () => {
    const path = menuOpen ? "en/topNav_cancel.png" : "en/topNav_hamburger.png";
    const name = menuOpen ? "topNav-cancel" : "topNav-hamburger";
    return (
      <div className="topNav-left-corner">
        <a onClick={handleMenuClick}>
          <img className={name} src={AppSettings.AWSImgRepo.resources + path} />
        </a>
      </div>
    );
  };

  const TopRightSection = () => {
    return (
      <div className="topNav-right-corner">
        {shouldShowTournamentsButton ? <img
          className="engage-xp-tournaments-button"
          alt=""
          src={
            AppSettings.AWSImgRepo.engageXP +
            (isMobileView(window.innerWidth, 918)
              ? "tournaments/xpTournamentsButtonMobile.png"
              : "tournaments/xpTournamentsButtonDesktop.png")
          }
          onClick={() => {
            const shouldRedirectToTournaments = true;
            handleEngageXpButtonClick(shouldRedirectToTournaments)
          }}
        />: <img
        className="engage-xp-button"
        alt=""
        src={
          AppSettings.AWSImgRepo.resources +
          (isMobileView(window.innerWidth)
            ? "en/engage-xp-button-mobile.png"
            : "en/engage-xp-button.png")
        }
        onClick={() => {
          const shouldRedirectToTournaments = false;
          handleEngageXpButtonClick(shouldRedirectToTournaments)
        }}
      />}
        <img
          alt=""
          src={AppSettings.AWSImgRepo.resources + "en/topNav_search.png"}
          className={"topNav-search"}
          onClick={() => toggleSearchBar()}
        />
        {!isMobileView(window.innerWidth) && (
          <Avatar
            profileUrl={profileUrl}
            analytics={analytics ? analytics.clickAnalytics.profile : null}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <BookmarkModal
        bookmarksVisible={bookmarksVisible}
        setBookmarksVisible={setBookmarksVisible}
      />
      {GrayOverlay()}
      <MainMenu
        menuOpen={menuOpen}
        menuData={topNavMenuData}
        clearMenuSelections={clearMenuSelections}
        bookmarkCallback={bookmarkCallback}
        analytics={analytics ? analytics.clickAnalytics : null}
      />
      {window.ReactNativeWebView ? null : (
        <div className={"notification-nav-container hide-for-print"}>
          <OutageAlerts setPadding={props.setNotificationPadding} />
          <div className={`top-nav-container`}>
            {TopLeftIcon()}
            <EngageLogo
              clearMenuSelections={clearMenuSelections}
              analytics={analytics ? analytics.clickAnalytics.engage : null}
            />
            {TopRightSection()}
          </div>
          <TopNavSearchBar
            notificationHeight={props.notificationHeight}
            displaySearchBar={displaySearchBar}
            setDisplaySearchBar={setDisplaySearchBar}
            analytics={analytics ? analytics.clickAnalytics : null}
          />
        </div>
      )}
    </>
  );
};

TopNav.propTypes = {
  userId: PropTypes.string,
  userImage: PropTypes.string,
  updateHideContent: PropTypes.func,
};

export default TopNav;
