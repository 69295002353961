import React from 'react';
import '../../styles/scss/downloadFileButton.scss';
import downloadIcon from '../../resources/images/grey-download-icon.png';
const DownloadFileButton = ({ articleId, fileName, description, index }) => {

  //Return relative path of file
  const fileURLPath = (articleId, fileName) => {
    return `/static/articles/${articleId}/${fileName}`;
  }

  const formatFileName = (fileName) => fileName.replace(/\.(zip|jpeg|png|jpg|pdf|pptx|docx)$/, '');

  return (
    <div key={index} className='download-file-container'>
      <div className='download-text'> <b>{description}</b> - {formatFileName(fileName) || 'File failed to load'}</div>
      <a className='download-inner-container' target="_blank" href={fileURLPath(articleId, fileName)} download>
        <div className='download-link-text'>
          {formatFileName(fileName) || 'File failed to load'}
        </div>
        <img
          alt="Download"
          className='download-link-arrow'
          src={downloadIcon}
        />
      </a>
    </div>
  );
};

export default DownloadFileButton;