import React, { useEffect, useContext } from 'react';
import { UserManagerContext } from '../contexts/userManagerContext';

const LoginService = () => {
  const { userManager } = useContext(UserManagerContext);

  try {
    useEffect(() => {
      const signIn = () => {
        if (userManager) {
          userManager.signinRedirect();
        }
      };

      signIn();
    }, [userManager]);
  } catch (error) {
    console.log(error);
  }
  return <div>Redirecting to login page...</div>;
};

export default LoginService;
