import { React, useEffect, useState } from 'react'
import '../../../styles/css/topNav/search/topNavSearchBar.css';
import { isVin, cleanString } from '../../utils/helpers';
import AppSettings from '../../../appsettings.js';
import { generalNavButtonClicked, searchButtonClicked } from '../topNav.analytics';

const TopNavSearchBar = (props) => {
    const [inputText, setInputText] = useState('');
    const [href, setHref] = useState('');

    useEffect(() => {
        if (inputText) {
            const encodedSearchString = encodeURIComponent(cleanString(inputText));
            const hrefLink = encodedSearchString.length > 1 && isVin(inputText) 
                ? `/vinLookup?${cleanString(inputText)}` 
                : encodedSearchString.length  ? `/search/?q=${encodedSearchString}` 
            : "#";
            setHref(hrefLink)
        } else {
            setHref(null)
        }
    }, [inputText])


    useEffect(() => {
        if (props.displaySearchBar) {
            document.getElementById("topNavSearchInput").focus();
        } else {
            setInputText('');
        }
    }, [props.displaySearchBar])

    const hideSearchBar = () => {
        generalNavButtonClicked(props.analytics.searchClear);
        props.setDisplaySearchBar(false);
    }

    const goSearchAnalytics = () => {
        searchButtonClicked(props.analytics.searchGo, inputText);
    }

    const handleEnterKey = (e) => {
        if (e.key === 'Enter') {
            document.getElementById("topNavSearchGoButton").click();
        }
    }

    const handleInputChange = (e) => {
        setInputText(e.target.value)
    }

    return (
        <div
            className={`topNavSearchDropdown ${props.displaySearchBar ? "displaySearch" : "hideSearch"}`}
            style={{
                "--topSpacement": `${props.notificationHeight + 75}px`,
                "--topSpacementMobile": `${props.notificationHeight + 60}px`,
            }}
        >
            <div className="topNavSearchBar">
                <img className="searchIcon" src={AppSettings.AWSImgRepo.resources + 'en/' + "topNav_searchV2.png"} />
                <input 
                    className="topNavSearchInput"
                    id="topNavSearchInput"
                    type="text" 
                    placeholder="Search Engage"
                    value={inputText}
                    onChange={handleInputChange}
                    onKeyDown={handleEnterKey}
                />
                <div className="clearSearch" onClick={() => hideSearchBar()}>
                    <img className="xIcon" src={AppSettings.AWSImgRepo.resources + 'en/' + "icon_close_black_small.png"} />
                </div>
            </div>
            <a className={`topNavSearchGoButton ${inputText && "active"}`} id="topNavSearchGoButton" href={href} onClick={()=>goSearchAnalytics()} >
                <span>GO</span>
            </a>
        </div>

    )
}

export default TopNavSearchBar;