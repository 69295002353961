import React, { useState, useEffect } from "react";
import ModelCodeNameTiles from "../modelCodeNameTiles";
import "../../../styles/scss/details-v2/featuresSpecs.scss";
import AppSettings from '../../../appsettings';
import { isDash } from "../../utils/helpers";

const FeaturesAccordionContent = (props) => {
    const displayAvailableModels = (models, availability, icon) => {
        const availableModels = models?.filter(t => t.availability === availability)

        if (availableModels?.length > 0) {
            return (
                <div className="availabilityTypeModels">
                    <div className="availabilityIcon">
                        <img src={`${AppSettings.AWSImgRepo.resources}en/${icon}`} />
                    </div>
                    <ModelCodeNameTiles models={availableModels} />
                </div>
            )
        }
    }

    const getAvailabilityResults = (models) => {
        return <div className={`featureResultColumn ${props.comparisonModelAvailability && "comparedResults"}`}>
            {props.comparisonModelAvailability && <div className="trimName">{models.trimName}</div>}
            {models?.availability?.every(i => isDash(i.availability)) ?
                <div className="notAvailable">Not Available</div>
                : <div className="availabilitiesList">
                    {displayAvailableModels(models.availability, "S", "filled-standard.svg")}
                    {displayAvailableModels(models.availability, "O", "filled-option.svg")}
                    {displayAvailableModels(models.availability, "P", "filled-package.svg")}
                </div>
            }
        </div>
    }

    return <div className="featuresAccordionContent">
        {getAvailabilityResults(props.modelAvailability)}
        {props.comparisonModelAvailability && getAvailabilityResults(props.comparisonModelAvailability)}
    </div>
}

export default FeaturesAccordionContent