import React from 'react';
import ReactDOM from 'react-dom';
import App from './screens/app/App';
import 'react-loading-skeleton/dist/skeleton.css'
import '../node_modules/react-responsive-modal/styles.css';


ReactDOM.render(
    <App />,
  document.getElementById('root')
);
