/* eslint-disable react/jsx-no-target-blank */
import dompurify from 'dompurify';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage } from 'react-intl';
import AppSettings from '../../appsettings.js';
import Display404NotFound from '../../components/atoms/404NotFound';
import downloadIcon from '../../resources/images/grey-download-icon.png';
import printIcon from '../../resources/images/print_icon.png';
import BookmarkPageComponent from '../bookmarks/bookmarkPage.component';
import EmailModalComponent from '../emailModal/emailModal.component';
import { formatDateAbbreviated } from '../utils/formatDate.js';
import { getRedlineDisplay } from '../utils/getArticleCategories';
import VideoPlayerComponent from '../videoPlayer/videoPlayer.component';
import CommentThread from './CommentThreadComponent';
import CommentTextArea from './CommentTextArea.js';
import MobileBackButton from '../../components/molecules/MobileBackButton.js';
import ShareModalButtonWrapper from '../shareModal/shareModalButtonWrapper.js';
import { ReactComponent as ShareIcon } from '../../resources/images/share-icon.svg';
import DownloadFileButton from './DownloadFileButton.js';

const sanitizeHtml = require('sanitize-html');

dompurify.addHook('afterSanitizeAttributes', (node) => {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
  }
});

export function ArticleTemplate(component) {
  const createLinkContent = (content) => {
    content = content.toLowerCase().replace(/eshowroom.toyota.com\/e[sS]howroom\//g, 'engage.toyota.com/');

    return (
      <div key={component.state.id}>
        <div className="uppercase">
          {<FormattedMessage id="article.readArticle" />}
        </div>
        <div className="article-link">
          <a className="article-link-text" target="_blank" onClick={() => component.sendClickAnalytics('Main Article', component.state.title)} href={content}>
            {component.state.title ? component.state.title : 'File filed to load'}
          </a>
          <ul className="article-link-tool-list">
          </ul>
        </div>
        <div className="short-description">{component.state.description}</div>
      </div>
    );
  };

  const formatFilePath = (content) => {
    // Strip out any old cloudfront domain URLs and use relative path only
    if (content.startsWith('http')) {
      return content.replace(/http:\/\/cf.eshowroom-dev.deops.toyota.com/g, '')
        .replace(/http:\/\/cf.eshowroom-stage.deops.toyota.com/g, '')
        .replace(/http:\/\/cf.eshowroom-prod.deops.toyota.com/g, '')
        .replace(/https:\/\/eshowroom.toyota.com/g, '')
        .replace(/https:\/\/staging.eshowroom.toyota.com/g, '')
        .replace(/http:\/\/eshowroom.toyota.com/g, '')
        .replace(/http:\/\/staging.eshowroom.toyota.com/g, '');
    } if (content.startsWith('/static/')) {
      return content;
    }
    return `/static${content}`;
  };

  const createFileContent = content => (
    <div key={component.state.id}>
      <div className="uppercase">
        {<FormattedMessage id="article.downloadFile" />}{' - '}{component.state.title ? component.state.title : <FormattedMessage id="article.fileFailedToLoad" />}
      </div>
      <div className="article-link">
        <div className="article-link-text">
          <a target="_blank" href={formatFilePath(content)}>
            {component.state.title ? component.state.title : 'File filed to load'}
            <img alt="" className="article-link-arrow" onClick={component.clickDownload} src={downloadIcon} />
          </a>
        </div>
      </div>
      <div className="short-description">{component.state.description}</div>
    </div>
  );

    const DynamicComponentInjector = (articleId) => {
      useEffect(() => {
        const targetElements = document.querySelectorAll('#downloadFileButton-id');
        
        // Get all elements with the class 'horizontal-line-widget to update their widget with proper styling'
        const horizontalLineWidgets = document.querySelectorAll('.horizontal-line-widget');
        horizontalLineWidgets.forEach(element => {
          element.style.width = '100%';
          element.style.borderTop = '0.5px solid #535454';
          element.style.paddingTop = '0px';
      });

        targetElements.forEach((element, index) => {
          if (!element.hasAttribute('isRendered')) {
            const originalText = element.textContent || element.innerText;
            element.innerHTML = '';
            ReactDOM.render(
              <DownloadFileButton
                articleId={articleId.articleId}
                fileName={originalText}
                description="DOWNLOAD FILE"
                index={index}
              />,
              element
            );
            element.setAttribute('isRendered', 'true');
          }
        });
      }, []);
    
      return null;
    };
    
  const createVideoContent = (id) => {
    // Qumu ID's are alphanumeric, whereas BC ID's are simply numeric.
    // Check the id to determine the type before passing it into the VideoPlayerComponent
    const regex = /[A-Za-z]/g;
    const match = !!id.match(regex);
    return (
        <VideoPlayerComponent
          item={{
            qumuId: match ? id : null,
            alias: match ? null : id,
            videoTitle: component.state.title,
            pageTitle: `Article Page: ${component.state.title}`,
            hideCTA: component.state.article.videoHideShare ? component.state.article.videoHideShare : false,
          }}
          sendVideoEventAnalytics={component.sendVideoEventAnalytics}
        />
    );
  };

  const createImageContent = content => (
    <img className="articleImage" alt="" src={AppSettings.AWSImgRepo.articleImages + content} />
  );

  const createTextContent = (content) => {
    const contentWithCleanImagePath = content.replace(/img[ ]{1,}src="images/g, `img src="${AppSettings.AWSImgRepo.articleImages}/images`);
    const contentCleanLinks = contentWithCleanImagePath.replace(/eshowroom.toyota.com\//g, 'engage.toyota.com/')
      .replace(/\/e[sS]howroom\//g, '/')
      .replace(/http:\/\/cf.eshowroom-dev.deops.toyota.com/g, '')
      .replace(/http:\/\/cf.eshowroom-stage.deops.toyota.com/g, '')
      .replace(/http:\/\/cf.eshowroom-prod.deops.toyota.com/g, '')
      .replace(/https:\/\/eshowroom.toyota.com/g, '')
      .replace(/https:\/\/staging.eshowroom.toyota.com/g, '')
      .replace(/http:\/\/eshowroom.toyota.com/g, '')
      .replace(/http:\/\/staging.eshowroom.toyota.com/g, '');

    const clean = sanitizeHtml(contentCleanLinks, {
      allowedTags: false, // Allows all tags
      allowedClasses: false,
      allowedAttributes: false,
      parseStyleAttributes: false,
      allowedIframeHostnames: ['www.youtube.com', 'player.vimeo.com', 'capture.navattic.com'],
      allowVulnerableTags: true, // Silences warning from allowing all tags
    });

    if (clean.includes('navattic')) {
      return (
        <div className='navattic-container'>
          <div dangerouslySetInnerHTML={{ __html: clean }} />
        </div>
      )
    }
    else {
    return (
          <div dangerouslySetInnerHTML={{ __html: clean }} />
    );}
  };

  const content = component.state.content ? component.state.content.map((c) => {
    switch (c.contentType.toLowerCase()) {
      case 'link':
        return createLinkContent(c.contentValue);
      case 'file':
        return  component.state.content.filter(item => item.contentType === 'File').length < 2 && createFileContent(c.contentValue);
      case 'video':
        return createVideoContent(c.contentValue);
      case 'text':
        if (c.contentValue.includes('itms-services://?action=download-manifest')) {
          return createTextContent(c.contentValue);
        }
        return createTextContent(c.contentValue);

      case 'subtitle':
        return (
          <h4 dangerouslySetInnerHTML={{ __html: dompurify.sanitize(c.contentValue) }} />
        );
      case 'image':
        return createImageContent(c.contentValue);
      default:
        return null;
    }
  }) : null;

  const tags = component.state.tags ? component.state.tags.map((tag) => {
    const skillsUrl = `/engageResources/tag/${encodeURIComponent(tag)}`;
    return (
      <div className="article-tag"><a href={skillsUrl}>{tag}</a></div>
    );
  }) : null;


  const decodeComment = (comment) => {
    try {
      comment = decodeURI(comment);
      return comment;
    } catch (error) {
      console.log(error);
      return comment;
    }
  };

  const shouldDisplayUnpromoteButton = (admin, comment) => {
    if (comment.activeReply || comment.activeDelete) {
      return false;
    }
    if (comment.isPromoted === false) {
      return false;
    }
    return admin;
  };

  const unpromoteComment = (admin, comment) => {
    if (shouldDisplayUnpromoteButton(admin, comment)) {
      if (comment.activeUnpromote) {
        return (
          <div className="active-comment-button-container">
            <div className="active-button-prompt"><FormattedMessage id="article.unpromoteComment" /></div>
            <div className="active-button-options">
              <button className="action-button" onClick={component.unpromoteCommentClick.bind(component, comment)}><FormattedMessage id="article.unpromote" /></button>
              <button className="action-button" onClick={component.activeUnpromoteClick.bind(component, comment)}><FormattedMessage id="global.button.cancel" /></button>
            </div>
          </div>
        );
      }
      return (
        <p className="comment-button-container">
          <a className="comment-button" onClick={component.activeUnpromoteClick.bind(component, comment)}><FormattedMessage id="article.unpromote" /></a>
        </p>
      );
    }
  };

  const promotedComments = component.state.promotedComments ? component.state.promotedComments.slice(0, 3).map(promotedComment => (
    <div className="promoted-comment-container">
      <blockquote>
        <p className="promoted-comment-text">{decodeComment(promotedComment.comment)}</p>
        <div className="promoted-comment-profile">
          <p className="promoted-comment-user">
            {`${promotedComment.userFirstName.toUpperCase()} ${promotedComment.userLastName.toUpperCase()}`}
          </p>
          <p className="promoted-comment-role">{AppSettings.JobCode[promotedComment.userRole]}</p>
          <br />
          <p className="promoted-comment-dealership">{promotedComment.userDealership}</p>
          <br />
          {unpromoteComment(localStorage.isAdmin, promotedComment)}
        </div>
      </blockquote>
    </div>
  )) : null;

  const relatedContent = component.state.relatedContent ? component.state.relatedContent.map(relatedArticle => (
    <div className="related-content-article">
      <div className="related-body-bold" onClick={() => component.sendRelatedClickAnalytics('28.3', 'Articles', relatedArticle.title, 'Related Content')}><a href={`/articles/${relatedArticle.id}`}>{relatedArticle.title}</a></div>
      <div className="related-body-regular">{relatedArticle.description}</div>
    </div>
  )) : null;

  const promotedCommentDisplay = () => {
    if (component.state.promotedComments && component.state.promotedComments.length > 0) {
      return (
        <div>
          <p className="promoted-comment-title"><FormattedMessage id="article.editorsTopPicks" /></p>
          <div className="promoted-comments">
            {promotedComments}
          </div>
        </div>
      );
    }
  };

  const getDisplayCategory = () => {
    const categories = component.state.article && component.state.article.categories ? component.state.article.categories : null;
    const articleFormat = component.state.article && component.state.article.format || 'Article';
    if (categories) {
      return getRedlineDisplay(categories, articleFormat);
    }
    return null;
  };

  const displayCategory = getDisplayCategory();

  return (
    <div>
      {
        component.state.article || component.state.noResponse
          ? <div className="article-page">
            {component.state.article
              && <div className="tools-container">
                <div className="smaller-tool-set">
                  <BookmarkPageComponent title={component.state.title} url={component.props.location.pathname}></BookmarkPageComponent>
                  <div className="hide-for-small-only printer-image-container">
                    <img className="printer-image" alt="" src={printIcon} onClick={component.printArticle.bind()} />
                  </div>
                  <div className='share-button-container'>
                    <ShareModalButtonWrapper linkToBeShared={component.state.linkToBeShared} analyticsFunction={component.shareArticleAnalytics.bind()}>
                      <button>
                        <ShareIcon height="30" width="30" color="#8C8C8C"/>
                      </button>
                    </ShareModalButtonWrapper>
                  </div>
                </div>
              </div>
            }
            <div className="article">
              <div className="article-header">
                <div className="overrideText" style={{ display: component.state.languageOverride ? 'block' : 'none' }}>{component.state.languageOverride ? <FormattedMessage id='dataUnavailable' /> : ''}</div>
                {component.state.noResponse && <div><Display404NotFound display /></div>}
                <span style={{display: 'flex'}}>
                  {
                    window.ReactNativeWebView &&
                    <>
                      <MobileBackButton />
                      <div style={{width: 32}} />
                    </>
                  }
                  <div>
                    <div className="article-type">{displayCategory ? <FormattedMessage id={`global.button.${displayCategory.toLowerCase()}`} /> : ''}</div>
                    <div className="red-border" style={{ display: displayCategory ? 'block' : 'none' }} />
                  </div>
                </span>
              </div>
              <div className="article-body row">
                <div className="body-left columns medium-9 small-12">
                  <div className="title">{component.state.title}</div>
                  <p className="article-date">{formatDateAbbreviated(component.state.date)}</p>
                  <div className="article-body" id="articleContent">
                    {content}
                    <DynamicComponentInjector articleId={component.state.id}/>
                  </div>
                  <div className="promoted-comment-section">
                    {promotedCommentDisplay()}
                  </div>
                  <div className="article-tags">
                    {tags}
                  </div>
                  {component.state.noResponse ? ''
                    : <div className="comment-section">
                      <div className="comment-title"><FormattedMessage id="comments.title" /></div>
                      <div className="comments">
                        <CommentTextArea 
                          value={component.state.textBoxInput}
                          onSubmit={component.submitCommentClick}
                          onChange={(evt) => { component.updateTextBoxInputValue(evt.target.value); }} 
                          component={component} 
                        />
                        {component.state.comments &&  component.state.comments?.map((comment, index) => 
                          <CommentThread
                              showAdminButtons
                              comment={comment}
                              key={index}
                              analyticsData={component.analyticsData}
                              component={component}
                              activeDeleteClick={component.activeDeleteClick}
                              deleteCommentClick={component.deleteCommentClick}
                          />
                        )}
                      </div>

                      {component.state.lastKey && (<div className="comments-more">
                        <button className="show-button" ref="showMore" onClick={component.showMoreComments}><FormattedMessage id="global.button.loadMore" /></button>
                      </div>)}
                    </div>
                  }
                </div>
                <div className="body-right columns medium-3 small-12">
                  {component.state.noResponse ? ''
                    : <div>
                      <div className="usage">
                        <div className="usage-title"><FormattedMessage id="article.usage" /></div>
                        <div className="red-border-right" />
                        <div className="usage-body">{(component.state.article && component.state.article.categories.includes('internal articles')) || displayCategory === 'cfa' ? <FormattedMessage id="article.internal" />: <FormattedMessage id="article.customerFriendly" />}</div>
                      </div>
                      <div className="related-content">
                        <div className="related-title"><FormattedMessage id="article.relatedContent" /></div>
                        <div className="red-border-right" />
                        <div className="related-content-articles">
                          {relatedContent}
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
            <EmailModalComponent ref={(instance) => { component.emailModal = instance; }} />
          </div>
          : null
      }
    </div>
  );
}
