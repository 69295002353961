/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import TSSLineupTemplate from './tssLineup.tpl';
import { getFormattedName } from '../../utils/vehicleName.js';
import { postUserActivityRecord } from '../../../services/userActivityService.js';

const analytics = require('./tssLineup.analytics.js');


export class TSSLineupComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      yearDropdown: [],
      modelDropdown: [],
      trimDropdown: [],
      selectedYear: null,
      selectedModel: null,
      selectedTrim: null,
      lineupItem: null,
      languageOverride: this.props.languageOverride,
    };

    this.selectYear = this.selectYear.bind(this);
    this.selectModel = this.selectModel.bind(this);
    this.selectTrim = this.selectTrim.bind(this);
    this.setLineupItem = this.setLineupItem.bind(this);
    this.getSelectedTrim = this.getSelectedTrim.bind(this);
    this.getSelectedModel = this.getSelectedModel.bind(this);
    this.isTrimDropdownDisabled = this.isTrimDropdownDisabled.bind(this);

    this.sendPageLoadAnalytics = analytics.sendPageLoadAnalytics.bind(this);
    this.sendYearSelectionAnalytics = analytics.sendYearSelectionAnalytics.bind(this);
    this.sendModelSelectionAnalytics = analytics.sendModelSelectionAnalytics.bind(this);
    this.sendTrimSelectionAnalytics = analytics.sendTrimSelectionAnalytics.bind(this);
    this.sendSubmitClickAnalytics = analytics.sendSubmitClickAnalytics.bind(this);
  }

  isTrimDropdownDisabled() {
    let disabled = false;

    if (this.state.trimDropdown && this.state.trimDropdown.length === 1) {
      disabled = true;
    }

    return disabled;
  }

  getAvailableYears() {
    const { lineupData } = this.props;

    const availableYears = [];

    for (const item in lineupData) {
      availableYears.push(lineupData[item].year);
    }

    const sortedAvailableYears = TSSLineupComponent.sortYearsNewestToOldest(availableYears);


    this.setState({
      yearDropdown: sortedAvailableYears,
    });

    return sortedAvailableYears;
  }

  static sortYearsNewestToOldest(years) {
    // uses JS standard sort function
    years.sort((a, b) => b - a);

    return years;
  }

  selectYear(event) {
    const year = event.target.value;
    const availableModels = this.getAvailableModels(year);
    let alphabetizedModels = availableModels.sort(TSSLineupComponent.compareModelNames);
    //Once VAPI and MYCO work is finished, this can be removed:
    alphabetizedModels = (year === '2025') ?
      alphabetizedModels.map(item => item.model === 'Prius Prime'
        ? { ...item, model: 'Prius Plug-In Hybrid' } : item.model === 'RAV4 Prime'
          ? { ...item, model: 'RAV4 Plug-In Hybrid' } : item)
      : alphabetizedModels;

    this.setState({
      selectedYear: year,
      modelDropdown: alphabetizedModels,
      trimDropdown: [],
      selectedModel: null,
      selectedTrim: null,
      lineupItem: null,
    });
    this.sendYearSelectionAnalytics(year);
  }

  getAvailableModels(year) {
    const { lineupData } = this.props;
    let availableModels = [];

    for (const item in lineupData) {
      if (lineupData[item].year === year) {
        availableModels = lineupData[item].models;
      }
    }

    return availableModels;
  }

  static compareModelNames(modelA, modelB) {
    const modelNameA = modelA.model.toLowerCase();
    const modelNameB = modelB.model.toLowerCase();

    if (modelNameA < modelNameB) return -1;
    if (modelNameA > modelNameB) return 1;
    return 0;
  }

  selectModel(event) {
    const model = event.target.value;
    const availableTrims = this.getAvailableTrims(model);

    if (availableTrims.length < 2) {
      this.setState({
        selectedModel: model,
        trimDropdown: availableTrims,
        selectedTrim: availableTrims[0],
        lineupItem: null,
      });
      this.setLineupItem(availableTrims[0]);
    } else {
      this.setState({
        selectedModel: model,
        trimDropdown: availableTrims,
        selectedTrim: null,
        lineupItem: null,
      });
    }
    this.sendModelSelectionAnalytics(model);
  }

  getAvailableTrims(model) {
    const formattedModel = getFormattedName(model);
    const availableModels = this.getAvailableModels(this.state.selectedYear);
    let availableTrims = [];
    for (const item in availableModels) {
      if (formattedModel.toLowerCase() === availableModels[item].model.toLowerCase()) {
        if (availableModels[item].trims) {
          availableTrims = availableModels[item].trims;
        } else {
          const trim = {
            headerImage: availableModels[item].headerImage,
            package: availableModels[item].package,
            trim: 'Trim',
          };

          availableTrims.push(trim);
        }
      }
    }

    return availableTrims;
  }

  selectTrim(event) {
    const trim = event.target.value;
    const availableTrims = this.getAvailableTrims(this.state.selectedModel);
    let selectedTrimObject = null;

    for (const item in availableTrims) {
      if (trim === availableTrims[item].trim) {
        selectedTrimObject = availableTrims[item];
      }
    }

    this.setState({
      selectedTrim: selectedTrimObject,
      lineupItem: null,
    });
    this.sendTrimSelectionAnalytics(trim);
    this.setLineupItem(selectedTrimObject);
  }

  setLineupItem(trimObj) {
    const packageObj = this.getPackage(trimObj.package);

    const lineupItem = {
      packageName: packageObj.version,
      features: this.getTrimFeatures(packageObj.features),
      headerImage: trimObj.headerImage,
    };

    this.setState({
      lineupItem,
    });

    this.sendSubmitClickAnalytics('Submit');

    return lineupItem;
  }

  getTrimFeatures(featuresString) {
    const featureIds = featuresString.split(',');
    const featuresMap = this.props.featuresData;
    const features = [];

    for (let feature = 0; feature < featureIds.length; feature++) {
      features.push(featuresMap[featureIds[feature]]);
    }

    return features;
  }

  getPackage(packageId) {
    const packageData = this.props.packagesData;
    let packageObj;

    for (let packageItem = 0; packageItem < packageData.length; packageItem++) {
      if (packageData[packageItem].id === packageId) {
        packageObj = packageData[packageItem];
      }
    }

    return packageObj;
  }

  getSelectedModel() {
    let selectedModel = 'Year';
    if (this.state.selectedModel) {
      // eslint-disable-next-line prefer-destructuring
      selectedModel = this.state.selectedModel;
    }
    return selectedModel;
  }

  getSelectedTrim() {
    let selectedTrim = 'Trim';
    if (this.state.selectedTrim) {
      selectedTrim = this.state.selectedTrim.trim;
    }
    return selectedTrim;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lineupData) {
      if (prevProps.lineupData.length !== this.props.lineupData.length) {
        this.getAvailableYears();
      }
    }
  }

  componentDidMount() {
    postUserActivityRecord();
    this.sendPageLoadAnalytics();
    this.getAvailableYears();
  }

  render() {
    return TSSLineupTemplate(this);
  }
}

TSSLineupComponent.propTypes = {
  languageOverride: PropTypes.bool.isRequired,
  lineupData: PropTypes.array,
  featuresData: PropTypes.object,
  packagesData: PropTypes.array,
};

export default injectIntl(TSSLineupComponent);
